
import axios from "axios";
import { omitFields } from "../_redux/logmiddleware";
const cartFieldsToLog = [
  "deliveryCharge",
  "currentUserTime",
  "items",
  "orderMSTObj", 
  "paymentModeCharges",
  "paymentObj",
  "paypalOrderId",
  "error",
  "paypalOrderTotal",
  // "placedOrderMSTObj",
  "savedAmount",
  "subTotal",
  "surcharges",
  "voucher",
  "totalItems",
  "voucherCode",
];

const fieldsToOmitMain = ["orderDTLWebRequestSet", "expressOrder","offersAllItem",'itemObj']; 


const logDataToAPI = (data, title) => {
  const isLoggingEnabled = process.env.REACT_APP_LOGGING_ENABLED === "true";
  
  if (isLoggingEnabled) {
    const logData = { ...data, title };

    const payload = JSON.stringify({ log: logData });

    axios
      .post(process.env.REACT_APP_API_URL + "/log/create/", payload)
      .catch((error) => console.error("Error logging:", error));
  }
};

const logCartData = (cartStore, title) => {
  if (cartStore?.items && cartStore.items.length > 0) {
    let filteredItems = cartStore.items.map((item) => {

      const { itemObj, ...rest } = item;
      return omitFields(rest, fieldsToOmitMain); 
        });

    const cartLogData = cartFieldsToLog.reduce((acc, field) => {
      if (field === "items") {
        acc[field] = filteredItems
      } else if(field === "orderMSTObj") {
        acc[field] = omitFields(cartStore[field], fieldsToOmitMain);
      } else if (cartStore[field] !== undefined) {
        debugger
        acc[field] = cartStore[field];
      }
      return acc;
    }, {});

    logDataToAPI(cartLogData, title);
  }
};

const logAPIResponse = (responseData, title) => {
  if (responseData) {
    logDataToAPI(responseData, title);  
  }
};

export default logCartData;  
export { logAPIResponse };  
//     if (!fieldsToOmit.includes(key)) {
//       if (key === 'recipeObj') {
//         try {
//           if (Array.isArray(obj?.recipeObj?.Toppings)) {
//             acc[key] = {
//               ...obj[key],
//               Toppings: obj?.recipeObj?.Toppings.filter(topping => topping.itemQuantity > 0)
//             };
//           } else {
//             acc[key] = obj[key]; 
//           }
//         } catch (error) {
//           console.error('Error processing recipeObj:', error); 
//           acc[key] = obj[key];
//         }
//       } else if (key === "offeritem") {
//         try {
//           if (Array.isArray(obj?.offeritem)) {
//             acc[key] = obj?.offeritem.map(item => ({
//               ...item,
//               itemObj: {},
//               recipeObj: {
//                 ...item?.recipeObj,
//                 Toppings: item?.recipeObj?.Toppings ? item.recipeObj.Toppings.filter(topping => topping.itemQuantity > 0) : undefined
//               }
//             }));
//           } else {
//             acc[key] = obj[key];
//           }
//         } catch (error) {
//           console.error('Error processing offeritem:', error);
//           acc[key] = obj[key];
//         }
//       } else {
//         acc[key] = obj[key];
//       }
//     }
//     return acc;
//   }, {});
// };