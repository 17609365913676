import axios from "axios";
import { v5 as uuidv5 } from "uuid";
import { isEqual } from 'lodash';

const REDUCER_NAME = "cart";
const actionTypesToLog = [
  `${REDUCER_NAME}/setOrderMST`,
  `${REDUCER_NAME}/addItem`,
  `${REDUCER_NAME}/setQty`,
    // `${REDUCER_NAME}/setState`,
  `${REDUCER_NAME}/deleteItem`,
  `${REDUCER_NAME}/stopCall`,
  `${REDUCER_NAME}/setVoucher`,
  `${REDUCER_NAME}/setQtyOfTopping`,
  `${REDUCER_NAME}/replaceItem`,
  `${REDUCER_NAME}/updateCartItem`,
];

const NAMESPACE = "123e4567-e89b-12d3-a456-426614174000";
export const generateUUID = (value) => {
  return uuidv5(value, NAMESPACE);
};

export const getOrCreateUUIDForTab = () => {
  let tabUUID = sessionStorage.getItem("tabUUID");

  if (!tabUUID) {
    const newUUID = generateUUID(`tab-${new Date().getTime()}`);
    sessionStorage.setItem("tabUUID", newUUID);
    tabUUID = newUUID;
  }

  return tabUUID;
};

export const cartFieldsToLog = [
  "deliveryCharge",
  "currentUserTime",
  "items",
  "orderMSTObj",
  "paymentModeCharges",
  "paymentObj",
  "error",
  "paypalOrderId",
  "paypalOrderTotal",
  "placedOrderMSTObj",
  "savedAmount",
  "subTotal",
  "surcharges",
  "voucher",
  "totalItems",
  "voucherCode",
];
const fieldsToOmit = [
  "itemObj",
  "image",
  "comboRefId",
  "orderDTLWebRequestSet",
  "offersAllItem",
];

const fieldsToOmitMain = ["orderDTLWebRequestSet", "expressOrder","offersAllItem",'itemObj']; 

let timeoutId;

const logMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    const prevState = getState()[REDUCER_NAME];



    const result = next(action); 
    const currentState = getState()[REDUCER_NAME];

    if (actionTypesToLog.includes(action.type)) {
      // clearTimeout(timeoutId);

      // timeoutId = setTimeout(() => {
      // Helper function to compare only specified fields (cartFieldsToLog)
      const findChangedFields = (prev, current, fieldsToLog) => {
        const changes = {};
        fieldsToLog.forEach((field) => {
          

          if (Array.isArray(current[field])) {
            // Compare items array
            const changedItems = current[field].reduce(
              (acc, currentItem, index) => {
                const prevItem = prev[field] ? prev[field][index] : null;

                // const { itemObj: _, ...currentItemWithoutObj } = currentItem;
                // const { itemObj: __, ...prevItemWithoutObj } = prevItem || {};

                const currentItemWithoutObj = omitFields(
                  currentItem,
                  fieldsToOmit
                );
                const prevItemWithoutObj = omitFields(
                  prevItem || {},
                  fieldsToOmit
                );

                if (prevItem) {
                  // Compare individual items in the array
                  const itemChanged = !isEqual(currentItemWithoutObj, prevItemWithoutObj);

                  if (itemChanged) {
                    acc.push(currentItemWithoutObj); // Log the whole item if it changed
                  }
                } else {
                  // If the item is new, log it entirely
                  acc.push(currentItemWithoutObj);
                }

                return acc;
              },
              []
            );

            if (changedItems.length > 0) {
              changes[field] = changedItems;
            }
          } else if (
            typeof current[field] === "object" &&
            current[field] !== null
          ) {
            // For nested objects, do a deep comparison
            if (
              JSON.stringify(prev[field]) !== JSON.stringify(current[field])
            ) {
              
              changes[field] = omitFields(current[field], fieldsToOmitMain);
            }
          } else if (prev[field] !== current[field]) {
            // For primitive values
            
            changes[field] = current[field];
          }
        });



        return changes;
      };

      const changedFields = findChangedFields(
        prevState,
        currentState,
        cartFieldsToLog
      );

      if (Object.keys(changedFields).length > 0) {
        const logPayload = {
          uuid: getOrCreateUUIDForTab(),
          changedFields, 
          actionType: action.type, 
        };

        let val = JSON.stringify(logPayload);

        axios
          .post(process.env.REACT_APP_API_URL + "/log/create/", val)
          .catch((error) => console.error("Error logging:", error));
      }
      // }, 300);
    }

    return result;
  };

export default logMiddleware;

export const omitFields = (obj, fieldsToOmit) => {
  return Object.keys(obj).reduce((acc, key) => {    
    if (!fieldsToOmit.includes(key)) {
      if (key === 'recipeObj') {
        try {
          if (Array.isArray(obj?.recipeObj?.Toppings)) {
            acc[key] = {
              ...obj[key],
              Toppings: obj?.recipeObj?.Toppings.filter(topping => topping.itemQuantity > 0)
            };
          } else {
            acc[key] = obj[key]; 
          }
        } catch (error) {
          console.error('Error processing recipeObj:', error); 
          acc[key] = obj[key];
        }
      } else if (key === "offeritem") {
        try {
          if (Array.isArray(obj?.offeritem)) {
            acc[key] = obj?.offeritem.map(item => ({
              ...item,
              itemObj: {},
              recipeObj: {
                ...item?.recipeObj,
                Toppings: item?.recipeObj?.Toppings ? item.recipeObj.Toppings.filter(topping => topping.itemQuantity > 0) : undefined
              }
            }));
          } else {
            acc[key] = obj[key];
          }
        } catch (error) {
          console.error('Error processing offeritem:', error);
          acc[key] = obj[key];
        }
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});
};